//sass-lint:disable class-name-format no-ids id-name-format

span {
  &.datetimeshortcuts {
    display: block;
  }
}

.admindatefilter {
  .vDateFields {
    width: 50% !important; //sass-lint:disable-line no-important
  }

  .datetimeshortcuts {
    display: inline-block !important; //sass-lint:disable-line no-important
    line-height: 3rem;
    width: 50% !important; //sass-lint:disable-line no-important
  }

  .datetimeshortcuts a {
    margin-left: .3rem !important; //sass-lint:disable-line no-important
  }

  .datetimeshortcuts a:first-child {
    margin-left: 3rem !important; //sass-lint:disable-line no-important
  }
}

.calendarbox.module {
  z-index: 9999999;
}

#content .module,
.module {
  padding: 15px;
}

.change-list #changelist.filtered .actions,
.change-list #changelist.filtered .paginator,
.change-list #changelist.filtered .results,
.change-list #changelist.filtered .xfull {
  margin-right: 0 !important; //sass-lint:disable-line no-important
}

.tabular table tbody td {
  padding: 8px 5px !important; //sass-lint:disable-line no-important
}

// FIXME: Port these styles to nephila-apps

.main #nav-sidebar.sticky {
  padding-top: 123px;
  top: 0;
}

#user-tools {
  align-items: center;
  margin-right: 5px !important; //sass-lint:disable-line no-important
  margin-top: 0 !important; //sass-lint:disable-line no-important

  a {
    border-bottom: 0 !important; //sass-lint:disable-line no-important
  }
}

#logout-form {
  box-shadow: none;
  margin-bottom: 0;
  margin-left: 15px;
  padding: 0;

  button {
    padding: 6px !important; //sass-lint:disable-line no-important
  }
}

.theme-toggle {
  align-items: center;
  display: flex;
  height: 26px;
  justify-content: center;
  margin-top: 0;
  padding: 0 !important; //sass-lint:disable-line no-important
  width: 24px;

  svg {
    margin-right: 0;
  }
}

#bookmark-form {
  margin-right: 25px;
  padding: 0;
}

#bookmark-button {
  height: 26px !important; //sass-lint:disable-line no-important
  margin-top: 0 !important; //sass-lint:disable-line no-important
}

#nav-filter {
  border: 0;
  margin-top: 0;
  padding: 10px 15px !important; //sass-lint:disable-line no-important
}

.submit-row {
  align-items: center;

  .deletelink,
  .deletelink-box {
    height: auto !important; //sass-lint:disable-line no-important
    margin-bottom: 0;
    margin-left: auto;
  }
}

#changelist-filter {
  select {
    height: 2.25rem;
  }
}

#content-main form .inline-group {
  width: 100%;
}
//sass-lint:enable class-name-format no-ids id-name-format

.change-list {
  .changelist-form-container {
    .toplinks {
      gap: 12px; //sass-lint:disable-line no-misspelled-properties

      a {
        background: white;
        border: 1px solid;
        padding: 4px 6px;
      }
    }
  }
}
